import React from "react";
import ReactDOM from "react-dom";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import GeneralMiddleWare from "middleware/GeneralMiddleware";

const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

const Membership = () => {
  const [membershipPackages, setMembershipPackages] = React.useState([
    {
      id: 1,
      token: 10,
      amount: 1,
      title: "10 tokens - 1 AUD",
    },
    {
      id: 2,
      token: 100,
      amount: 5,
      title: "100 tokens - 5 AUD",
    },
    {
      id: 3,
      token: 1000,
      amount: 10,
      title: "1000 tokens - 10 AUD",
    },
    {
      id: 4,
      token: 10000,
      amount: 100,
      title: "10000 tokens - 100 AUD",
    },
    {
      id: 5,
      token: 100000,
      amount: 1000,
      title: "100000 tokens - 1000 AUD",
    },
  ]);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [availableTokens, setAvailableTokens] = React.useState(0);

  React.useEffect(() => {
    setSelectedValue(membershipPackages[0]);
    _getAvailableTokens();
  }, []);

  const _getAvailableTokens = () => {
    GeneralMiddleWare.get(
      `user/get_available_tokens/${localStorage.getItem("sessionUserId")}`
    ).then((res) => {
      if (res.success == true) {
        setAvailableTokens(res.data.data.availableTokens);
      } else {
        console.log(res);
      }
    });
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: selectedValue.amount,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    if (actions.order) {
      const body = {
        id: localStorage.getItem("sessionUserId"),
        tokenValue: selectedValue.token,
        spendings: selectedValue.amount,
      };
      GeneralMiddleWare.post("user/update_available_token", body).then(
        (res) => {
          if (res !== null) {
            if (res.success == true) {
              _getAvailableTokens();
            }
          } else {
            console.log("PayPal Page");
          }
        }
      );
    }
    return actions.order.capture();
  };

  const handleChange = (event) => {
    // console.log('ONCHANGE EVENT', event.target.value,membershipPackages[event.target.value-1])
    const index = event.target.value;
    setSelectedValue(membershipPackages[index - 1]);
  };

  return (
    <Container fluid>
      <div>
        <Card className="strpied-tabled-with-hover">
          <div className="row justify-content-center">
            <div className="col-3 mt-4">
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                Available Tokens
              </span>
            </div>
            <div className="col-2 mt-4 text-right">
              <span
                style={{
                  fontSize: 20,
                  backgroundColor: "#c7c7c7",
                  padding: 5,
                  color: "#fff",
                  borderRadius: 10,
                  fontWeight: "bold",
                }}
              >
                {availableTokens}
              </span>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-5 m-5">
              <span className="text-muted">Purchase more tokens</span>
              <select
                className="form-control mt-2 mb-5"
                onChange={handleChange}
              >
                {membershipPackages.map((mp) => (
                  <option key={mp.id} value={mp.id}>
                    {mp.title}
                  </option>
                ))}
              </select>
              <PayPalButton
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
              />
            </div>
          </div>
        </Card>
      </div>
    </Container>
  );
};

export default Membership;
