import React from "react";

import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Card,
  Modal,
} from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import GeneralMiddleWare from "middleware/GeneralMiddleware";
import { BlockChainUrl } from "../config/HomeUrl";
import { Callbacks } from "jquery";

const Stats = (props) => {
  const [formId, setFormId] = React.useState(props.location.state.formId);
  const [blockChainLinkErr, setBlockChainLinkErr] = React.useState("");
  const [dataArray, setDataArray] = React.useState([]);
  const [metaStreamUUid, setMetaStreamUUid] = React.useState({
    uuids: [],
  });
  const [urls, setUrls] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState(null);
  const [responseMsg, setResponseMsg] = React.useState("");
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    GeneralMiddleWare.post("user/get_answers", { form_id: formId }).then(
      (json) => {
        if (json !== null) {
          if (json.success === true && json.data.length > 0) {
            var obj = {};
            const ansArr = json.data;
            // console.log(ansArr)
            const keysArr = Object.keys(ansArr[0]);
            const valuesArr = Object.values(ansArr[0]);
            for (let i = 0; i < keysArr.length; i++) {
              if (keysArr[i] !== "metastremeUUID" && keysArr[i] !=='txid') {
                let arr = [];
                for (let j = 0; j < ansArr.length; j++) {
                  if (ansArr[j][keysArr[i]] != undefined) {
                    arr.push(ansArr[j][keysArr[i]].value);
                  }
                }
                obj[keysArr[i]] = arr;
              }
            }
            for (let i = 0; i < keysArr.length; i++) {
              if (keysArr[i] !== "metastremeUUID" && keysArr[i] !=='txid') {
                let data = count(obj[keysArr[i]]);
                let type = keysArr[i].split("-");
                const dataObj = {
                  title: valuesArr[i].label,
                  type: type[0],
                  data: data,
                };
                // setDataArray([...dataArray,dataObj])
                dataArray.push(dataObj);
              }
            }
            // console.log(dataArray)
            for (let i = 0; i < dataArray.length; i++) {
              drawGraph(dataArray[i], i + 1);
            }
          } else {
            setErr("No Stats to show");
            setBlockChainLinkErr("Please try later");
            console.log(json.success);
          }
        } else {
          setErr("No Stats to show");
        }
      }
    );
  }, []);

  // method definations
  /**
   * @method
   * counts the no of occurrence of answers
   * @returns array
   */

  function count(array_elements) {
    array_elements.sort();
    let mapArray = [];
    let current = null;
    let cnt = 0;
    for (let i = 0; i < array_elements.length; i++) {
      if (array_elements[i] != current) {
        if (cnt > 0) {
          let noOfAns = [];
          noOfAns.push(current);
          noOfAns.push(cnt);
          mapArray.push(noOfAns);
        }
        current = array_elements[i];
        cnt = 1;
      } else {
        cnt++;
      }
    }

    if (cnt > 0) {
      let noOfAns = [];
      noOfAns.push(current);
      noOfAns.push(cnt);
      mapArray.push(noOfAns);
    }
    mapArray.splice(0, 0, ["Answers", "Occurrence"]);
    return mapArray;
  }

  /**
   * @method
   * @param {object} dataObj
   * @param {int} elementId
   * @draws chart
   */
  function drawGraph(dataObj, elementId) {
    let divId = `div${elementId}`;
    createElem(dataObj.type, divId);
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawVisualization);

    function drawVisualization() {
      var data = google.visualization.arrayToDataTable(dataObj.data);

      var options = {
        title: dataObj.title,
        seriesType: "bars",
      };

      if (dataObj.type === "radio") {
        var chart = new google.visualization.PieChart(
          document.getElementById(divId)
        );
        chart.draw(data, options);
      } else if (
        dataObj.type === "text" ||
        dataObj.type == "autocomplete" ||
        dataObj.type == "paragraph" ||
        dataObj.type == "textarea"
      ) {
        let data = toStr(dataObj.data);
        drawWordCloud(data, divId, dataObj.title);
      } else {
        var chart = new google.visualization.ComboChart(
          document.getElementById(divId)
        );
        chart.draw(data, options);
      }
    }
  }

  function drawWordCloud(text, div, title) {
    var lines = text.split(/[,\. ]+/g),
      data = Highcharts.reduce(
        lines,
        function (arr, word) {
          var obj = Highcharts.find(arr, function (obj) {
            return obj.name === word;
          });
          if (obj) {
            obj.weight += 1;
          } else {
            obj = {
              name: word,
              weight: 1,
            };
            arr.push(obj);
          }
          return arr;
        },
        []
      );
    Highcharts.chart(div, {
      accessibility: {
        screenReaderSection: {
          beforeChartFormat:
            "<h5>{chartTitle}</h5>" +
            "<div>{chartSubtitle}</div>" +
            "<div>{chartLongdesc}</div>" +
            "<div>{viewTableButton}</div>",
        },
      },
      series: [
        {
          type: "wordcloud",
          data: data,
          name: "Occurrences",
        },
      ],
      title: {
        text: title,
      },
      credits: {
        enabled: false,
      },
    });
  }

  /**
   * @method converts 2d array into array of objects
   * @param {array} arr
   * @returns array of objects
   */

  function toStr(arr) {
    let data = "";
    for (let i = 1; i < arr.length; ++i) {
      let rv = "";
      for (let j = 0; j < 2; ++j) {
        if (arr[i][0] !== undefined) rv = arr[i][0].replace(/\+/g, " ");
        // if (arr[i][1] !== undefined) rv['weight'] = arr[i][1];
      }
      data = data + " " + rv;
    }

    return data;
  }

  /**
   * @method
   * @param {char} type
   * @param {int} divId
   * @creates new div elements
   */

  function createElem(type, divId) {
    if (type == "radio") {
      let div = document.createElement("DIV");
      div.className = "col-8";
      div.innerHTML =
        '<div class="fadeInDown"><div id="' +
        divId +
        '" class="barchart-card"></div></div>';
      // document.getElementById("pie").appendChild(offset);
      document.getElementById("pie").appendChild(div);
    } else if (
      type == "text" ||
      type == "autocomplete" ||
      type == "paragraph" ||
      type == "textarea"
    ) {
      let div = document.createElement("DIV");
      div.className = "col-8";
      div.innerHTML =
        '<div class="mt-4 fadeInDown"><div id="' +
        divId +
        '" class="barchart-card"></div></div>';
      document.getElementById("wordCloud").appendChild(div);
    } else {
      let div = document.createElement("DIV");
      div.className = "col-8";
      div.innerHTML =
        '<div class="mt-4 fadeInDown"><div id="' +
        divId +
        '" class="barchart-card"></div></div>';
      document.getElementById("bar").appendChild(div);
    }
  }

  const GenerateUrl = () => {
    setLoading(true);
    setShow(false);
    setBlockChainLinkErr(null);
    GeneralMiddleWare.post("user/get_metaStream", { form_id: formId }).then(
      (json) => {
        if (json !== null) {
          setLoading(true);
          setShow(false);
          if (json.success && json.success === true && json.data.length > 0) {
            const ansArr = json.data;
            let uuidArr = [];
            for (let i = 0; i < ansArr.length; i++) {
              if (ansArr[i].metastremeUUID) {
                uuidArr.push(ansArr[i].txid);
              }
            }
            setUrls(json.data);
            setLoading(false);
          } else {
            setErr("No Stats to show");
            setBlockChainLinkErr("Please try again later");
            setLoading(false);
            console.log(json.success);
          }
        } else {
          setShow(true);
          setBlockChainLinkErr("Please try again later");
          setLoading(false);
        }
      }
    );
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="tabContainer" style={{ width: "80%" }}>
          <Tabs
            defaultActiveKey="first"
            className="bg-white"
            style={{ color: "red" }}
          >
            <Tab eventKey="first" title="Graphs">
              <Container fluid>
                <Row>
                  {err ? (
                    <Alert variant={"danger"}>
                      <span>{err}</span>
                    </Alert>
                  ) : (
                    ""
                  )}

                  <div class="row justify-content-center" id="bar"></div>
                  <div class="row justify-content-center mt-4" id="pie"></div>
                  <div class="row justify-content-center" id="wordCloud"></div>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="second" title="BlockChain">
              <Container fluid>
                <Row>
                  <Col>
                    <div className="d-flex p-3 justify-content-end">
                      <button
                        disabled={loading ? true : false}
                        onClick={GenerateUrl}
                        className={"btn btn-dark mt-2"}
                      >
                        {" "}
                        Generate
                      </button>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {blockChainLinkErr ? (
                      <Alert variant={"danger"}>
                        <span>{blockChainLinkErr}</span>
                      </Alert>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                {loading && (
                  <Row>
                    <Col
                      md={12}
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "20rem" }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{
                          fontSize: "2rem",
                          height: "4rem",
                          width: "4rem",
                          color: "",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Col>
                  </Row>
                )}
                {urls && (
                  <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                      <div className="container">
                        <h3 style={{ fontWeight: "700" }}>Urls</h3>
                      </div>
                      {urls &&
                        urls.map((item) => {
                          return (
                            <Card body className="mt-1">
                              <a
                                target="_blank"
                                href={BlockChainUrl + item.txid}
                              >
                                {BlockChainUrl + item.txid}
                              </a>
                            </Card>
                          );
                        })}
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                )}
              </Container>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Stats;
