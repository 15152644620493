import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
require('jquery-ui-sortable');
require('formBuilder/dist/form-render.min.js');
import {
  Container,
  Modal,
  Card,
  Button,
  Row,
  Col,
  Image,
} from 'react-bootstrap';
import Logo from '../../assets/img/anonsurveyheader.png';
import GeneralMiddleWare from 'middleware/GeneralMiddleware';
import { useHistory } from 'react-router-dom';

const Response = (props) => {
  const [formId, setFormId] = useState(null);
  const [token, setToken] = useState(null);
  const [publishStatus, setPublishStatus] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  // const [labels, setLabels] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const formView = useRef();
  const history = useHistory();

  useEffect(() => {
    // console.log(props.location.search)
    // setIsLoading(true)
    let urlObj = new URL(location.href);
    let searchParams = new URLSearchParams(urlObj.search);
    var urlFormId = searchParams.get('form_id');
    setFormId(urlFormId);
    var urlToken = searchParams.get('token');
    setToken(urlToken);
    // let formArr = []
    // let tempLabel = {}
    // let publishStatus = false;
    GeneralMiddleWare.get(
      `user/get_form?form_id=${urlFormId}&token=${urlToken}`
    ).then((res) => {
      if (res.success == true) {
        if (res.data.success == true) {
          if (res.data.data.isValid === true) {
            // console.log("USER FORM", res.data.data);
            $(formView.current).formRender({
              dataType: 'json',
              formData: res.data.data.form,
            });
            setFormTitle(res.data.data.formTitle);
            setPublishStatus(res.data.data.publishStatus);
            setIsLoading(false)
            // formArr = JSON.parse(res.data.data.form)

            // for(let i=0; i<formArr.length; i++){
            //   tempLabel[formArr[i].type] = formArr[i].label
            // }
            // setLabels(tempLabel)
            // console.log(tempLabel)
          } else {
            setErr('Invalid token.');
            setShowModal(true);
            setIsLoading(false)
          }
        } else {
          setErr('No form available');
          setShowModal(true);
          setIsLoading(false)
        }
      } else {
        setErr('Failed to fetch form. Network error occured');
        setShowModal(true);
        setIsLoading(false)
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isDisabled) {
      return;
    }
    setIsLoading(true);
    let inputs = e.target;
    let ans = {};
    let label = '';
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type == 'radio' && inputs[i].checked === true) {
        label = $("label[for='" + inputs[i].name + "']").html();
        ans[inputs[i].name] = {
          label: label,
          value: inputs[i].value,
        };
      } else if (inputs[i].type == 'radio' && inputs[i].checked === false) {
        if (ans[inputs[i].name] == undefined) {
          label = $("label[for='" + inputs[i].name + "']").html();
          ans[inputs[i].name] = {
            label: label,
            value: 'no response',
          };
        }
      } else if (inputs[i].type != 'radio' && inputs[i].name != '') {
        if (inputs[i].type == 'checkbox' && inputs[i].checked == true) {
          label = $(
            "label[for='" + inputs[i].name.replace('[]', '') + "']"
          ).html();
          ans[inputs[i].name] = {
            label: label,
            value: inputs[i].value,
          };
        } else if (inputs[i].type == 'checkbox' && inputs[i].checked == false) {
          if (ans[inputs[i].name] == undefined) {
            label = $(
              "label[for='" + inputs[i].name.replace('[]', '') + "']"
            ).html();
            ans[inputs[i].name] = {
              label: label,
              value: 'no response',
            };
          }
        } else if (inputs[i].type != 'checkbox' && inputs[i].name != '') {
          label = $("label[for='" + inputs[i].name + "']").html();
          ans[inputs[i].name] = {
            label: label,
            value: inputs[i].value,
          };
        }
      }
    }
    // console.log(ans);
    var reqData = {
      form_id: formId,
      answers: ans,
      tokenCheck: token,
      pubStatus: publishStatus,
    };
    setDisabled(!isDisabled);

    GeneralMiddleWare.post('user/submit_answers', reqData).then((res) => {
      if (res.success == true) {
        history.push('/success');
        setIsLoading(false);
      } else {
        setErr('Failed to submit the survey.');
        setShowModal(true);
        setIsLoading(false);
      }
    });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md='2'></Col>
          <Col md='8'>
            <Card className='strpied-tabled-with-hover mt-5 bg-light'>
              <Card.Header className='bg-light'>
                <Card.Title
                  as='h3'
                  className='text-center'
                  style={{ fontWeight: 400 }}
                >
                  {/* <Image src={Logo} width={250} height={150} /> */}
                  {formTitle}
                </Card.Title>
                {/* <p className="card-category">
                  Here is a subtitle for this table
                </p> */}
              </Card.Header>
              <Card.Body className='table-full-width table-responsive p-5 pt-2'>
                {err ? (
                  err
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className='m-5' ref={formView}></div>
                    <div className='ms-5 mb-3'>
                      {publishStatus && (
                        <small className='text-danger'>
                          Your form response will be captured publicly on the
                          blockchain
                        </small>
                      )}
                    </div>
                    {isLoading == true ? (
                        <div className='spinner-border text-dark ml-5' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                      ) : (
                    <button
                      className='btn btn-filled ml-5'
                      disabled={isDisabled == true ? true : false}
                    >
                      
                        'Submit'
                      
                    </button>
                    )}
                  </form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          className='modal-mini modal-primary'
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header className='justify-content-center'>
            <div className='modal-profile'>
              <i className='nc-icon nc-bulb-63'></i>
            </div>
          </Modal.Header>
          <Modal.Body className='text-center'>
            <div className='row'>
              <div className='col-12'>{err ? err : msg ? msg : ''}</div>
            </div>
          </Modal.Body>
          <div className='modal-footer'>
            <Button
              className='btn-simple'
              type='button'
              variant='default'
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
      </Container>
    </>
  );
};

export default Response;
