import React from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-bootstrap components
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

const Notifications = () => {
  const [notification, setNotification] = React.useState([
      {id:1,
      notification:'Your survey has been created successfully',
      variant:'primary'},
      {id:2,
        notification:'Your suvery has been removed successfully',
        variant:'danger'},
      {id:3,
        notification:'We have reduce membership prices please review the latest plans',
      variant:'info'}
  ])
    
  return (
    <>
      <Container fluid>
        <Card>
          <Card.Header>
            <Card.Title as="h4">Notifications</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="12">
                  {notification.map(n=><Alert variant={n.variant}>
                  <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="alert"
                    type="button"
                  >
                    <i className="nc-icon nc-simple-remove"></i>
                  </button>
                  <span>{n.notification}</span>
                </Alert>
                    )}
                
                </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Notifications;
