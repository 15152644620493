import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import Logo from "../assets/img/anonsurveyheader.png";
import GeneralMiddleWare from "middleware/GeneralMiddleware";
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink, useHistory } from "react-router-dom";
import "./Styles.css";

const Login = () => {
  let history = useHistory();
  const [isResponseModal, setResponseModal] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("ababa");
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") != null &&
      localStorage.getItem("sessionUser") != null
    ) {
      history.push("/admin/dashboard");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      if (!isVerified) {
        setIsVerified(true);
        setCaptchaValue(null);
        return;
      }
      let data = {
        email: values.email.toLowerCase(),
        password: values.password,
      };
      const response = await GeneralMiddleWare.post("user/login", data);
      if (response !== null) {
        if (response.success) {
          console.log("response", response.data);
          if (response.auth_token) {
            localStorage.setItem(
              "availableToken",
              response.data.availableToken
            );
            localStorage.setItem("accessToken", response.auth_token);
            localStorage.setItem("sessionUser", response.data.email);
            localStorage.setItem("sessionUserId", response.data._id);
            setTimeout(() => {
              history.push("/admin/dashboard");
            }, 200);
          }
        } else {
          setResponseModal(!isResponseModal);
          setResponseMsg(response.data.msg);
        }
      } else {
      }
    },
  });

  const onCaptchaChange = (value) => {
    console.log("VALUES", value);
    setIsVerified(true);
    setCaptchaValue(value);
  };

  return (
    <Container className={"p-5 pb-0"} >
      <Card className={"bg-light"}>
        <Row className={"justify-content-center"}>
          <Row className={"justify-content-center"} >
            <Col xs={12} md={4} className={"text-center"}>
              <Image src={Logo} width={220} height={220} />
            </Col>
          </Row>
          <Col xs={12} md={5}>
            <Form onSubmit={formik.handleSubmit} className={"m-4"}>
              <Form.Group className="mb-2">
                <Form.Label htmlFor="email" className="formLabels">
                  Email Address
                </Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email.toLocaleLowerCase()}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="errorMsgTxt">{formik.errors.email}</div>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label htmlFor="password" className="formLabels">
                  Password
                </Form.Label>
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="errorMsgTxt">{formik.errors.password}</div>
                ) : null}
              </Form.Group>

              <Form.Group>
                <div className="col-12 rcp-container" id="recaptcha">
                  <ReCAPTCHA
                    
                    sitekey="6LdXnAwdAAAAADJjBtfUWD-yskxfDZm9n8_Fyf8Q"
                    onChange={onCaptchaChange}
                  />
                  {captchaValue === null && (
                    <small className="text-danger">
                      Please verify you are human
                    </small>
                  )}
                </div>
              </Form.Group>

              <Form.Group className="mb-2 text-center">
                <Button
                  type="submit"
                  className={"formButton"}
                  variant="dark"
                  size="lg"
                >
                  Login
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className={"justify-content-center seprator m-4"}>
          <Col md={6} className={"text-center"}>
            <h6 className={"txt-info"}>
              Doesn't have an account yet?
              <NavLink exact to="/register" className={"txt-link"}>
                Register
              </NavLink>{" "}
              |{" "}
              <NavLink exact to="/forgot_password" className={"txt-link"}>
                Forgot Password
              </NavLink>
            </h6>
          </Col>
        </Row>
      </Card>

      <Modal className="modal-mini modal-primary mt-5" show={isResponseModal}>
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-circle-09"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>{responseMsg}</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn btn-default"
            type="button"
            variant="link"
            onClick={() => {
              setResponseModal(!isResponseModal);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default Login;
