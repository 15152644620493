import React, { useState, useRef, useEffect } from "react";
import { Button, Container, Modal, Alert } from "react-bootstrap";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");
import GeneralMiddleWare from "middleware/GeneralMiddleware";

const CreateSurvey = (props) => {
  const [formName, setFormName] = useState(null);
  const [formNameErr, setFormNameErr] = useState(null);
  const [noOfUsers, setNoOfUsers] = useState(1);
  const [tokenErr, setTokenErr] = useState(null);
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(null);
  const [err, setErr] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [blockchainCheck, setBlockchainCheck] = useState(false);
  const [usersLimit, setUsersLimit] = useState(false);
  const fb = useRef();
  var [formBuilder, setFormBuilder] = useState([]);
  const [isResponseModal, setResponseModal] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [availableTokens, setAvailableTokens] = React.useState(
    localStorage.getItem("availableToken")
  );
  const [todayLimit, setTodayLimit] = useState(0);
  const [todayLimitErr, setTodayLimitErr] = useState(false);

  // console.log('indafsd')

  useEffect(() => {
    setFormBuilder($(fb.current).formBuilder());
    _getAvailableTokens();
    GeneralMiddleWare.post("user/get_forms_byuser", {
      user_id: localStorage.getItem("sessionUserId"),
    }).then((res) => {
      if (res !== null) {
        if (res.success == true) {
          let allForms = res.data;
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth() + 1;
          let yyyy = today.getFullYear();
          let dateToday = mm + "/" + dd + "/" + yyyy;
          let filtered = allForms.filter((f) => f.created_at == dateToday);
          setTodayLimit(filtered.length);
          console.log(filtered);
        } else {
        }
      } else {
      }
    });
  }, []);

  const _getAvailableTokens = () => {
    GeneralMiddleWare.get(
      `user/get_available_tokens/${localStorage.getItem("sessionUserId")}`
    ).then((res) => {
      if (res.success == true) {
        setAvailableTokens(res.data.data.availableTokens);
      }
    });
  };

  const handleSubmit = async () => {
    setIsButtonLoading(true);
    if (todayLimit > 9) {
      setTodayLimitErr(true);
      setIsButtonLoading(false);
      return;
    }
    if (parseInt(noOfUsers) > parseInt(availableTokens)) {
      alert(
        `Token limit exceeds! You have ${availableTokens} ${noOfUsers} token available`
      );
      setIsButtonLoading(false);
      return;
    }
    if (formName == "" || formName == null) {
      setFormNameErr("* Survey title is required");
      setIsButtonLoading(false);
      return;
    }

    if (noOfUsers < 1) {
      setTokenErr(`Number of tokens must be greater then 0`);
      setIsButtonLoading(false);
      return;
    }
    let formData = formBuilder.actions.getData("json");
    if (JSON.parse(formData).length < 1 || formData == null) {
      alert("please add fields to the form");
      setIsButtonLoading(false);
      return;
    }
    let data = {
      user_id: localStorage.getItem("sessionUserId"),
      publishBlockChain: blockchainCheck,
      no_of_tokens: noOfUsers,
      formName: formName,
      formJson: formData,
    };

    // alert(JSON.stringify(data))
    const response = await GeneralMiddleWare.post("user/update_form", data);
    if (response !== null) {
      if (response.success) {
        setResponseMsg(response.data.msg);
        setResponseModal(!isResponseModal);
        setIsButtonLoading(false);
        _getAvailableTokens();
      }
    } else {
      console.log("server is not responding at this moment");
    }
  };

  return (
    <Container fluid>
      {availableTokens < 1 && (
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div>
                <p className="text-danger">
                  Note:{" "}
                  <span>
                    Please ensure you have enough tokens purchased BEFORE you
                    create the form.
                  </span>
                </p>
              </div>
              <div>
                <p>
                  <span className="fw-bold mr-2">Available Tokens:</span>
                  <span className="text-muted">{availableTokens} </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex w-100 request-header">
              <h3 className="request-heading">Add Form</h3>
            </div>
          </div>
        </div>
        <div className="row">
          {show === true ? (
            <div
              className={
                msg
                  ? "alert alert-success alert-dismissible fade show"
                  : err
                  ? "alert alert-danger alert-dismissible fade show"
                  : "alert alert-info alert-dismissible fade show"
              }
              role="alert"
            >
              {msg ? msg : err ? err : ""}
              <button
                type="button"
                className="btn-close"
                onClick={() => setShow(false)}
              ></button>
            </div>
          ) : (
            ""
          )}
          <div className="col-7 mb-3">
            <lable className="fw-bold">Title:</lable>
            <input
              type="text"
              onChange={(e) => {
                setFormName(e.target.value);
                setFormNameErr(null);
              }}
              className="form-control mt-2"
            />
            {formNameErr ? (
              <small className="text-danger">{formNameErr}</small>
            ) : (
              ""
            )}
          </div>
          <div className="col-5 mb-3">
            <div className="flex ">
              <lable className="fw-bold">
                Number of tokens (for responses) to issue:
              </lable>
            </div>
            <input
              type="number"
              // placeholder={'Enter number of tokens to issue'}
              min={1}
              defaultValue={1}
              onChange={(e) => {
                setNoOfUsers(e.target.value);
                setTokenErr(null);
              }}
              className="form-control mt-2 col-12"
            />
            {tokenErr ? (
              <small className="text-danger mb-3">{tokenErr}</small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div id="fb-editor" ref={fb} />

            <div className="row">
              <div className="col-12 mt-3">
                {/* <input type="checkbox" id="publishBlockChain" name="publishBlockChain" value="publishBlockChain" /> */}
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={blockchainCheck}
                    onChange={() => setBlockchainCheck(!blockchainCheck)}
                  />
                  <span className="ml-2">
                    Publish Responses to Blockchain (make publicly verifiable)
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    // defaultChecked={usersLimit}
                    onChange={(e) => console.log(e.target.value)}
                    disabled
                  />
                  <span className="ml-2">Encrypt responses</span>
                </label>
                {/* {usersLimit &&  */}
                {/* <input
            type="number"
            placeholder={'Enter number of tokens to issue'}
            min={1}
            // defaultValue={1}
            onChange={(e) => {setNoOfUsers(e.target.value);setTokenErr(null)}}
            className="form-control my-2 col-4"
          /> */}
                {/* {tokenErr ? <small className="text-danger mb-3">{tokenErr}</small> : ""} */}
                {/* } */}
              </div>
            </div>
            {isButtonLoading === true ? (
              <div className="spinner-border spinner" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <button
                onClick={handleSubmit}
                title={"Save Form"}
                className={"btn btn-dark  mt-5"}
              >
                {" "}
                Save Form
              </button>
            )}
          </div>
        </div>
      </div>

      <Modal
        className="modal-mini modal-primary mt-5"
        show={isResponseModal}
        onHide={() => setResponseModal(!isResponseModal)}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>{responseMsg}</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn btn-dark"
            type="button"
            variant="link"
            onClick={() => {
              setResponseModal(!isResponseModal);
              setTimeout(() => {
                props.history.push("surveys");
              }, 100);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal
        className="modal-mini modal-primary mt-5"
        show={todayLimitErr}
        onHide={() => setTodayLimitErr(!todayLimitErr)}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Today Form Limit has been reached</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn btn-dark"
            type="button"
            variant="link"
            onClick={() => setTodayLimitErr(!todayLimitErr)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default CreateSurvey;
