import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder/dist/form-render.min.js");
import {
  Button,
  Card,
  Container,
  Modal,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { NavLink } from "react-router-dom";
import GeneralMiddleWare from "middleware/GeneralMiddleware";
import { CSVLink } from "react-csv";
import { BlockChainUrl } from "../config/HomeUrl";
import HomeUrl from "config/HomeUrl";
import "./Styles.css";

const SurveyList = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showMailModal, setShowMailModal] = React.useState(false);
  const [tokens, setTokens] = React.useState(null);
  const [formId, setFormId] = React.useState(null);
  const [mail, setMail] = React.useState("");
  const [mailFormName, setMailFormName] = React.useState("");
  const [subject, setSubject] = React.useState(null);
  const [reqSubject, setReqSubject] = React.useState(false);
  const [reqMail, setReqMail] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [mailList, setMailList] = React.useState([]);
  const [mailListValidation, setMailListValidation] = useState(null);
  const [mailListValidationError, setMailListValidationError] = useState(false);
  const [validTokens, setValidTokens] = useState([]);
  const [validTokensErr, setValidTokenErr] = useState(false);

  const handleTokenShow = (tokens, formId, tokenLimit, answers, formName) => {
    let tokensString = "";

    tokens.forEach((element) => {
      tokensString +=
        HomeUrl +
        "/response?form_id=" +
        formId +
        "&token=" +
        element.token +
        "\n";
    });
    let temp = tokens.filter((item) => !item.email);
    // console.log(temp);
    setValidTokens(temp);
    setTokens(tokensString);
    setFormId(formId);
    setMailListValidation(tokenLimit - (answers.length + 1));
    setMailFormName(formName);
    setShowModal(true);
  };

  const handleEmailToken = (tokens, formId) => {
    let tokensString = "";
    tokens.forEach((element) => {
      tokensString +=
        HomeUrl +
        "/response?form_id=" +
        formId +
        "&token=" +
        element.token +
        "\n";
    });
    setTokens(tokensString);
  };

  function copy() {
    var copyText = document.getElementById("tokensContent");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "Form Name",
        field: "formName",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "# Token",
        field: "noOfToken",
        width: 270,
      },
      {
        label: "# Responses",
        field: "noOfResponse",
        width: 200,
      },
      {
        label: "Create Date",
        field: "date",
        width: 150,
      },
      {
        label: "Stats",
        field: "stats",
        sort: "asc",
        width: 100,
      },
      {
        label: "Export",
        field: "export",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: [],
  });

  const getForms = () => {
    GeneralMiddleWare.post("user/get_forms_byuser", {
      user_id: localStorage.getItem("sessionUserId"),
    }).then((res) => {
      if (res !== null) {
        if (res.success == true) {
          let arr = [];
          res.data.forEach((element) => {
            let csvAns = [];
            let csvTemp = [];
            let csvTxidTemp = {}
            element.answers.forEach((ans) => {
              csvTemp = Object.values(ans);
              csvTxidTemp = ans;
              let csvObj = {};
              for (let i = 0; i < csvTemp.length; i++) {
                if (typeof csvTemp[i] === "object") {
                  let label = csvTemp[i].label;
                  let value = csvTemp[i].value;
                  csvObj[label] = value;
                }
              }
              if(csvTxidTemp.txid != undefined){
                csvObj['block_chain_url'] = `BlockChainUrl/${csvTxidTemp.txid}`;
              }
              csvAns.push(csvObj);
            });
            arr.push({
              formName: (
                <NavLink
                  to={{
                    pathname: "/admin/view_form",
                    state: {
                      data: {
                        formName: element.formName,
                        form: element.formJson,
                      },
                    },
                  }}
                  style={{ textDecoration: "none", color: "#212529" }}
                >
                  {element.formName}
                </NavLink>
              ),
              noOfToken: (
                <button
                  className={"btn-fill btn"}
                  onClick={() =>
                    handleTokenShow(
                      element.tokens,
                      element.formId,
                      element.tokenLimit,
                      element.answers,
                      element.formName
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  {element.tokenLimit}
                </button>
              ),
              noOfResponse: element.answers.length,
              date:
                element.created_at.split("/")[2] +
                "-" +
                element.created_at.split("/")[1] +
                "-" +
                element.created_at.split("/")[0],
              stats: (
                <NavLink
                  to={{
                    pathname: "/admin/stats",
                    state: { formId: element.formId },
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    variant="success"
                  >
                    <i
                      className={"nc-icon nc-chart-pie-36"}
                      style={{ fontSize: 20 }}
                    />
                  </Button>
                </NavLink>
              ),
              export: (
                <CSVLink data={csvAns} filename={Date.now() + "Requests.csv"}>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    variant="dark"
                  >
                    <i
                      className={"nc-icon nc-single-copy-04"}
                      style={{ fontSize: 20 }}
                    />
                  </Button>
                </CSVLink>
              ),
            });
          });
          setDatatable({
            columns: [
              {
                label: "Form Name",
                field: "formName",
                width: 150,
                attributes: {
                  "aria-controls": "DataTable",
                  "aria-label": "Name",
                },
              },
              {
                label: "# Token",
                field: "noOfToken",
                width: 270,
              },
              {
                label: "# Responses",
                field: "noOfResponse",
                width: 200,
              },
              {
                label: "Create Date",
                field: "date",
                width: 150,
              },
              {
                label: "Stats",
                field: "stats",
                sort: "asc",
                width: 100,
              },
              {
                label: "Export",
                field: "export",
                sort: "disabled",
                width: 100,
              },
            ],
            rows: arr.reverse(),
          });
        } else {
        }
      } else {
      }
    });
  };

  useEffect(() => {
    getForms();
  }, []);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const sendMail = () => {
    let validEmailList = true;
    if (subject == "" || subject == null) {
      setReqSubject(true);
      return;
    }
    if (mail == "" || mail == null) {
      setReqMail(true);
      return;
    }
    if (mailList.length < 1) {
      setReqMail(true);
      return;
    }
    if (mailList.length > validTokens.length) {
      setValidTokenErr(true);
      return;
    }
    mailList.map((item) => {
      if (!validateEmail(item)) {
        setIsValid(true);
        validEmailList = false;
        return;
      }
    });

    if (validEmailList === false) {
      return;
    }

    let data = {
      formId: formId,
      mailList: mailList,
      subject: subject,
    };

    GeneralMiddleWare.post("user/send_emails", data).then((res) => {
      if (res !== null) {
        if (res.Success === true) {
          setShowMailModal(false);
          setValidTokenErr(false);
          setMailList([]);
          setSubject(null);
          setMail(null);
          setMailListValidationError(false);
          setReqSubject(false);
          setReqMail(false);
          setIsValid(false);
          setMailListValidationError(false);
          alert(res.message);
        } else {
          alert(res.message);
        }
      }
    });
  };

  const addMail = (e) => {
    e.preventDefault();
    const mail = e.target.value;
    setReqMail(false);
    setMailListValidationError(false);
    if (mail == "" || mail == null) {
      setReqMail(true);
      return;
    }
    let tempList = mail.split(/\n/g);
    setMailList(tempList);
  };

  const closeMailListModal = () => {
    setShowMailModal(false);
    setValidTokenErr(false);
    setMailList([]);
    setSubject(null);
    setMail(null);
    setMailListValidationError(false);
    setReqSubject(false);
    setReqMail(false);
    setIsValid(false);
    setMailListValidationError(false);
  };

  const removeMail = (item) => {
    const arr = mailList.filter((element) => {
      return item !== element;
    });
    setMailList(arr);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">List of all surveys</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive p-5">
                <MDBDataTable
                  hover
                  bordered
                  entriesOptions={[5, 20, 25]}
                  entries={5}
                  pagesAmount={4}
                  data={datatable}
                  pagingTop
                  searchTop
                  searchBottom={false}
                  noBottomColumns
                />
              </Card.Body>
            </Card>
          </Col>

          {/* Mini Modal */}
          <Modal
            className="modal-primary custom-modal"
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header className="justify-content-center">
              <h3 className="font-weight-bold">Generated Tokens</h3>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div className="row">
                <div className="col-12">
                  <textarea
                    wrap="off"
                    readOnly
                    className="w-100"
                    id="tokensContent"
                    style={{ height: "300px", borderColor: "#e7e7e7" }}
                    value={tokens}
                  >
                    {" "}
                  </textarea>
                </div>
              </div>
            </Modal.Body>
            <div className="modal-footer">
              <Button
                className="btn-dark"
                type="button"
                variant="primary"
                onClick={() => copy()}
              >
                Copy to clipboard
              </Button>
              <Button
                className="btn btn-primary w-25"
                variant="danger"
                onClick={() => {
                  setShowMailModal(true);
                }}
              >
                <i className="fal fa-envelope" style={{ fontSize: 20 }}></i>
              </Button>
              <Button
                className="btn-simple"
                type="button"
                variant="default"
                onClick={() => setShowModal(false)}
              >
                Close
              </Button>
            </div>
          </Modal>
          {/* End Modal */}
          {/* Mail List  Modal */}
          <Modal
            className="modal-primary custom-modal"
            show={showMailModal}
            onHide={closeMailListModal}
          >
            <Modal.Header className="justify-content-center">
              <h3 className="font-weight-bold">
                Send Tokens{" "}
                <span
                  className="center-align"
                  style={{ color: "gray", fontSize: "1rem" }}
                >
                  ({mailFormName})
                </span>
              </h3>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  {validTokensErr && (
                    <Alert
                      variant="danger"
                      className="d-flex justify-content-between"
                    >
                      <div>
                        {validTokens.length == 0
                          ? "No tokens left to send"
                          : `You can only send to{" "}
                      ${validTokens.length} mail`}
                      </div>

                      <div
                        className="text-black"
                        style={{ cursor: "pointer" }}
                        onClick={() => setValidTokenErr(false)}
                      >
                        X
                      </div>
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row className="center-align mb-4">
                <Col md={8}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Subject"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                        setReqSubject(false);
                      }}
                    />
                    {reqSubject && (
                      <small className="text-danger">Subject is required</small>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="center-align">
                <Col md={8}>
                  <div className="input-group ">
                    <textarea
                      // type="text-area"
                      rows="10"
                      value={mail}
                      className="form-control h-25"
                      placeholder="Enter Email"
                      // aria-label=""
                      // aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setMail(e.target.value);
                        addMail(e);
                        setReqMail(false);
                        setIsValid(false);
                        setMailListValidationError(false);
                      }}
                    />
                  </div>
                  {reqMail && (
                    <small className="text-danger">
                      Email list is Required
                    </small>
                  )}
                  {isValid && (
                    <small className="text-danger">
                      Atleast one or more email is not Valid
                    </small>
                  )}
                  {mailListValidationError && (
                    <small className="text-danger">
                      No More tokens to send{" "}
                    </small>
                  )}
                </Col>
              </Row>
              {/* <Row className="center-align mt-2">
                <Col md={8} style={{ overflowY: "auto", height: "13rem" }}>
                  {mailList &&
                    mailList.map((item, idx) => {
                      return (
                        <Card key={idx}>
                          <Card.Body className="d-flex justify-content-between">
                            <div> {item}</div>
                            <div>
                              <i
                                className="fas fa-minus minu-icon"
                                style={{ color: "red" }}
                                onClick={() => removeMail(item)}
                              ></i>
                            </div>
                          </Card.Body>
                        </Card>
                      );
                    })}
                </Col>
              </Row> */}
            </Modal.Body>
            <div className="modal-footer">
              <Button
                className="btn-dark"
                type="button"
                variant="primary"
                onClick={sendMail}
              >
                Send Mail
              </Button>
              <Button
                className="btn-simple"
                type="button"
                variant="default"
                onClick={closeMailListModal}
              >
                Close
              </Button>
            </div>
          </Modal>
          {/* End Modal */}
        </Row>
      </Container>
    </>
  );
};

export default SurveyList;
