import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder/dist/form-render.min.js");
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useLocation, NavLink} from "react-router-dom";
import GeneralMiddleWare from "middleware/GeneralMiddleware";
import HomeUrl from "config/HomeUrl";
import { computeStyles } from "@popperjs/core";


const ViewForm = (props) => {
  const formView = useRef();
  useEffect(()=>{
    $(formView.current).formRender({
      dataType: "json",
      formData: props.location.state.data.form,
    });
  },[])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">{props.location.state.data.formName}</Card.Title>
                {/* <p className="card-category">
                  Here is a subtitle for this table
                </p> */}
              </Card.Header>
              <Card.Body className="table-full-width table-responsive p-5">
                <div className="m-5" ref={formView}></div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewForm;
