const Success = () => {
  return (
    <div className="d-flex justify-content-center fadeInDown">
      <div id="formContent" className="mt-5">
        <h1>Thank You!</h1>
        <p>
          <strong>For Submitting the Survey form.</strong> We hope you had a
          Great Experience.
        </p>
      </div>
    </div>
  );
};

export default Success;
