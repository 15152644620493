import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Alert,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import GeneralMiddleWare from "middleware/GeneralMiddleware";

function User() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isProfileLoading, setIsProfileLoading] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [err, setErr] = React.useState(null);
  const [profMsg, setProfMsg] = React.useState(null);
  const [profErr, setProfErr] = React.useState(null);

  React.useEffect(() => {
    GeneralMiddleWare.get(
      `user/get-profile/${localStorage.getItem("sessionUserId")}`
    ).then((res) => {
      if (res.success == true) {
        setEmail(res.data.data.email);
        setFirstName(res.data.data.firstName);
        setLastName(res.data.data.lastName);
      } else {
        console.log(res);
      }
    });
  }, [GeneralMiddleWare]);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      values["id"] = localStorage.getItem("sessionUserId");
      GeneralMiddleWare.post("user/update-password", values).then((res) => {
        if (res !== null) {
          if (res.success == true) {
            console.log(res);
            setMsg("Password updated successfully");
            setIsLoading(false);
          } else {
            setErr(res.err);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });
    },
  });

  const formikUpdateProf = useFormik({
    initialValues: {
      email: email,
      firstName: firstName,
      lastName: lastName,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setIsProfileLoading(true);
      values["id"] = localStorage.getItem("sessionUserId");
      GeneralMiddleWare.post("user/update-profile", values).then((res) => {
        if (res !== null) {
          if (res.success == true) {
            console.log(res);
            setProfMsg("Profile updated successfully");
            setIsProfileLoading(false);
          } else {
            setProfErr(res.err);
            setIsProfileLoading(false);
          }
        } else {
          setIsProfileLoading(false);
        }
      });
    },
  });
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Edit Profile</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={formikUpdateProf.handleSubmit}>
                  <Row>
                    {profMsg || profErr ? (
                      <Alert
                        variant={
                          profMsg ? "success" : profErr ? "danger" : "info"
                        }
                      >
                        <button
                          aria-hidden={true}
                          className="close"
                          data-dismiss="alert"
                          onClick={() => {
                            setProfErr(null);
                            setProfMsg(null);
                          }}
                          type="button"
                        >
                          <i className="nc-icon nc-simple-remove"></i>
                        </button>
                        <span>
                          {profMsg ? profMsg : profErr ? profErr : ""}
                        </span>
                      </Alert>
                    ) : (
                      ""
                    )}
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          // defaultValue={email}
                          id="email"
                          name="email"
                          value={formikUpdateProf.values.email}
                          onChange={formikUpdateProf.handleChange}
                          onBlur={formikUpdateProf.handleBlur}
                        />
                        {formikUpdateProf.touched.email &&
                        formikUpdateProf.errors.email ? (
                          <div className="errorMsgTxt">
                            {formikUpdateProf.errors.email}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control
                          value={formikUpdateProf.values.firstName}
                          id="firstName"
                          name="firstName"
                          placeholder="Company"
                          type="text"
                          onChange={formikUpdateProf.handleChange}
                          onBlur={formikUpdateProf.handleBlur}
                        />
                        {formikUpdateProf.touched.firstName &&
                        formikUpdateProf.errors.firstName ? (
                          <div className="errorMsgTxt">
                            {formikUpdateProf.errors.firstName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Last Name</label>
                        <Form.Control
                          value={formikUpdateProf.values.lastName}
                          onChange={formikUpdateProf.handleChange}
                          onBlur={formikUpdateProf.handleBlur}
                          placeholder="Last Name"
                          id="lastName"
                          name="lastName"
                          type="text"
                        />
                        {formikUpdateProf.touched.lastName &&
                        formikUpdateProf.errors.lastName ? (
                          <div className="errorMsgTxt">
                            {formikUpdateProf.errors.lastName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="dark"
                  >
                    {isProfileLoading == true ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Update Profile"
                    )}
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Update Password</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    {msg || err ? (
                      <Alert
                        variant={msg ? "success" : err ? "danger" : "info"}
                      >
                        <button
                          aria-hidden={true}
                          className="close"
                          data-dismiss="alert"
                          onClick={() => {
                            setErr(null);
                            setMsg(null);
                          }}
                          type="button"
                        >
                          <i className="nc-icon nc-simple-remove"></i>
                        </button>
                        <span>{msg ? msg : err ? err : ""}</span>
                      </Alert>
                    ) : (
                      ""
                    )}

                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Password</label>
                        <Form.Control
                          id="password"
                          name="password"
                          type="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="errorMsgTxt">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Confirm Password</label>
                        <Form.Control
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmPassword}
                        />
                        {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                          <div className="errorMsgTxt">
                            {formik.errors.confirmPassword}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="dark"
                  >
                    {isLoading == true ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Update Password"
                    )}
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default User;
