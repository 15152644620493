import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import SurveyList from "views/SurveyList.js";
import CreateSurvey from 'views/CreateSurvey.js'
import ViewForm from "views/ViewForm";
import Notifications from "views/Notifications.js";
import Membership from 'views/Membership'
import Stats from "views/Stats";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-grid-45",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/create-survey",
    name: "Create New Survey",
    icon: "nc-icon nc-notes",
    component: CreateSurvey,
    layout: "/admin",
  },
  {
    path: "/surveys",
    name: "Survey List",
    icon: "nc-icon nc-bullet-list-67",
    component: SurveyList,
    layout: "/admin",
  },
  {
    path: "/view_form",
    name: "Form View",
    icon: null,
    component: ViewForm,
    layout: "/admin",
  },
  {
    path: "/stats",
    name: "Stats",
    icon: null,
    component: Stats,
    layout: "/admin",
  },
  {
    path: "/membership",
    name: "Purchase Tokens",
    icon: "nc-icon nc-credit-card",
    component: Membership,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Profile",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin",
  },
];

export default dashboardRoutes;
