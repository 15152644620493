import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import Logo from "../assets/img/anonsurveyheader.png";
import { NavLink, useHistory } from "react-router-dom";
import "./Styles.css";
import GeneralMiddleWare from "middleware/GeneralMiddleware";
import ReCAPTCHA from "react-google-recaptcha";

const Register = (props) => {
  const [isResponseModal, setResponseModal] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [isVerified, setIsVerified] = React.useState(false);
  const [captchaValue, setCaptchaValue] = React.useState("ababa");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      cpassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      lastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
      cpassword: Yup.string().test(
        "passwords-match",
        "Passwords must match",
        function (value) {
          return this.parent.password === value;
        }
      ),
    }),
    onSubmit: async (values) => {
      if (!isVerified) {
        setIsVerified(true);
        setCaptchaValue(null);
        return;
      }
      let data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email.toLowerCase(),
        password: values.password,
      };
      const response = await GeneralMiddleWare.post("user/register", data);
      if (response !== null) {
        if (response.success) {
          if (response.data === "user already registered") {
            setResponseModal(!isResponseModal);
            setResponseMsg(
              "User Already Registered! Please login to access portal."
            );
          } else {
            setResponseModal(!isResponseModal);
            // setResponseMsg('Thanks! Your profile has been created. Please login to proceed.')
            setResponseMsg(
              "Thanks! Your profile has been created. Please check your email to verify your account."
            );
          }
        }
      } else {
      }
    },
  });

  const onCaptchaChange = (value) => {
    console.log("VALUES", value);
    setIsVerified(true);
    setCaptchaValue(value);
  };

  return (
    <Container className={"p-5"}>
      {/* <Row className={'justify-content-center'}>
            <Col xs={6} md={4} className={'text-center'}>
                <Image src={Logo} width={250} height={150}/>
            </Col>
        </Row> */}
      <Card className={"bg-light"}>
        <Row className={"justify-content-center"}>
          <Col xs={8} md={8} className={"m-3 text-center"}>
            <h2 className={"heading-txt"}>CREATE ACCOUNT</h2>
          </Col>
          <Col xs={6} md={5}>
            <Form onSubmit={formik.handleSubmit} className={"m-4"}>
              <Row className={"justify-content-center"}>
                <Form.Group as={Col} className="mb-2">
                  <Form.Label htmlFor="firstName" className="formLabels">
                    First Name
                  </Form.Label>
                  <Form.Control
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="errorMsgTxt">{formik.errors.firstName}</div>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col} className="mb-2">
                  <Form.Label htmlFor="lastName" className="formLabels">
                    Last Name
                  </Form.Label>
                  <Form.Control
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="errorMsgTxt">{formik.errors.lastName}</div>
                  ) : null}
                </Form.Group>
              </Row>

              <Form.Group className="mb-2">
                <Form.Label htmlFor="email" className="formLabels">
                  Email Address
                </Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email.toLocaleLowerCase()}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="errorMsgTxt">{formik.errors.email}</div>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label htmlFor="password" className="formLabels">
                  Password
                </Form.Label>
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="errorMsgTxt">{formik.errors.password}</div>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label htmlFor="cpassword" className="formLabels">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  id="cpassword"
                  name="cpassword"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpassword}
                />
                {formik.touched.cpassword && formik.errors.cpassword ? (
                  <div className="errorMsgTxt">{formik.errors.cpassword}</div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <div className="col-12 rcp-container text-right">
                  <ReCAPTCHA
                    sitekey="6LdXnAwdAAAAADJjBtfUWD-yskxfDZm9n8_Fyf8Q"
                    onChange={onCaptchaChange}
                  />
                  {captchaValue === null && (
                    <small className="text-danger">
                      Please verify you are human
                    </small>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="mb-2 text-center">
                <Button
                  type="submit"
                  className={"formButton"}
                  variant="dark"
                  size="lg"
                >
                  Register
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className={"justify-content-center seprator m-2"}>
          <Col md={4} className="text-center">
            <h6 className={"txt-info"}>
              Already have an account?
              <NavLink exact to="/login" className={"txt-link"}>
                Login
              </NavLink>
            </h6>
          </Col>
        </Row>
      </Card>

      <Modal className="modal-mini modal-primary mt-5" show={isResponseModal}>
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-circle-09"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>{responseMsg}</p>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn btn-dark mr-3"
            type="button"
            variant="link"
            onClick={() => {
              setResponseModal(!isResponseModal);
              setTimeout(() => {
                props.history.push("login");
              }, 100);
            }}
          >
            Login
          </Button>
          <Button
            className="btn btn-default"
            type="button"
            variant="link"
            onClick={() => {
              setResponseModal(!isResponseModal);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default Register;
