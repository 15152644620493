import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  Button,
  Image,
} from "react-bootstrap";
import Logo from "../assets/img/anonsurveyheader.png";
import { NavLink, useHistory } from "react-router-dom";
import "./Styles.css";
import GeneralMiddleWare from "middleware/GeneralMiddleware";
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassword = () => {
  let history = useHistory();
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [err, setErr] = React.useState(null);
  const [isVerified, setIsVerified] = React.useState(false);
  const [captchaValue, setCaptchaValue] = React.useState("ababa");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      //   history.push('/')
      // console.log(values)
      setIsLoading(true);
      if (!isVerified) {
        setIsVerified(true);
        setCaptchaValue(null);
        setIsLoading(false);
        return;
      }

      GeneralMiddleWare.post("user/forgot-password", values).then((res) => {
        if (res !== null) {
          if (res.success == true) {
            setSuccess(true);
            setIsLoading(false);
          } else {
            setSuccess(false);
            setErr(res.err);
            setIsLoading(false);
          }
        }
      });
    },
  });

  const onCaptchaChange = (value) => {
    console.log("VALUES", value);
    setIsVerified(true);
    setCaptchaValue(value);
  };
  return (
    <Container className={"p-5 pb-0"}>
      <Card className={"bg-light"}>
        <Row className={"justify-content-center"}>
          {/* <Col xs={8} md={8} className={'m-3 text-center'}>
        <h2 className={'heading-txt'}>Login</h2>
    </Col> */}
          <Row className={"justify-content-center"}>
            <Col xs={6} md={4} className={"text-center"}>
              <Image src={Logo} width={250} height={150} />
            </Col>
          </Row>
          <Col xs={6} md={5}>
            {success == true ? (
              <h5 className="m-5 text-center">Please check your email now</h5>
            ) : (
              <Form onSubmit={formik.handleSubmit} className={"m-4"}>
                <Form.Group className="mb-2">
                  {err ? <p className="text-danger">{err}</p> : ""}
                  <Form.Label htmlFor="email" className="formLabels">
                    Email Address
                  </Form.Label>
                  <Form.Control
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email.toLocaleLowerCase()}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="errorMsgTxt">{formik.errors.email}</div>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <div className="col-12 rcp-container text-right">
                    <ReCAPTCHA
                      sitekey="6LdXnAwdAAAAADJjBtfUWD-yskxfDZm9n8_Fyf8Q"
                      onChange={onCaptchaChange}
                    />
                    {captchaValue === null && (
                      <small className="text-danger">
                        Please verify you are human
                      </small>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="mb-2 text-center">
                  <Button
                    type="submit"
                    className={"formButton"}
                    variant="dark"
                    size="lg"
                    disabled={isLoading == true ? true : false}
                  >
                    {isLoading == true ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </Form.Group>
              </Form>
            )}
            {/*  */}
          </Col>
        </Row>
        <Row className={"justify-content-center seprator m-4"}>
          <Col md={6} className={"text-center"}>
            <h6 className={"txt-info"}>
              Doesn't have an account yet?
              <NavLink exact to="/register" className={"txt-link"}>
                Register
              </NavLink>{" "}
              |{" "}
              <NavLink exact to="/login" className={"txt-link"}>
                Login
              </NavLink>
            </h6>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default ForgotPassword;
