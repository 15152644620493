import React from "react";
import GeneralMiddleWare from "middleware/GeneralMiddleware";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const VerifyEmail = () => {
  const [success, setSuccess] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const history = useHistory();
  React.useEffect(() => {
    let urlObj = new URL(location.href);
    let searchParams = new URLSearchParams(urlObj.search);
    let email = searchParams.get("q");
    let urlToken = searchParams.get("token");
    GeneralMiddleWare.get(
      `user/verify_email?q=${email}&token=${urlToken}`
    ).then((res) => {
      if (res.success === true) {
        if (res.data.success === true) {
          setSuccess(true);
          setIsLoaded(true);
        } else {
          setSuccess(false);
          setIsLoaded(true);
        }
      } else {
        setSuccess(false);
        setIsLoaded(true);
      }
    });
  }, []);

  return (
    <>
      {isLoaded == true ? (
        <div
          className="d-flex justify-content-center fadeInDown"
          {...console.log(success)}
        >
          {success == true ? (
            <div id="formContent" className="mt-5">
              <h1>Congratulations!</h1>
              <p>
                <strong>Your Email has been verified. </strong>You can login
                now. We hope you have a Great Experience.
              </p>
              <Button
                className="btn btn-dark mr-3"
                type="button"
                variant="link"
                onClick={() => {
                  setTimeout(() => {
                    history.push("login");
                  }, 100);
                }}
              >
                Login
              </Button>
            </div>
          ) : (
            <div id="formContent" className="mt-5">
              <h1>Sorry!</h1>
              <p>
                <strong>Your email has not been verified. </strong>Please try
                again.
              </p>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default VerifyEmail;
