import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  Button,
  Image,
} from "react-bootstrap";
import Logo from "../assets/img/anonsurveyheader.png";
import { NavLink, useHistory } from "react-router-dom";
import "./Styles.css";
import GeneralMiddleWare from "middleware/GeneralMiddleware";

const ResetPassword = () => {
  let history = useHistory();
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [err, setErr] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [email, setEmail] = React.useState(null);

  React.useEffect(() => {
    let urlObj = new URL(location.href);
    let searchParams = new URLSearchParams(urlObj.search);
    setToken(searchParams.get("q"));
    setEmail(searchParams.get("email"));
  }, []);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      values["token"] = token;
      values["email"] = email.toLowerCase();
      GeneralMiddleWare.post("user/reset-password", values).then((response) => {
        if (response !== null) {
          if (response.success == true) {
            if (response.authToken) {
              localStorage.setItem("accessToken", response.authToken);
              localStorage.setItem("sessionUser", response.data.email);
              localStorage.setItem("sessionUserId", response.data._id);
              setTimeout(() => {
                setIsLoading(false);
                history.push("/admin/dashboard");
              }, 200);
            }
          } else {
            setErr(response.err);
            setIsLoading(false);
          }
        } else {
        }
      });
    },
  });
  return (
    <Container className={"p-5 pb-0"}>
      <Card className={"bg-light"}>
        <Row className={"justify-content-center"}>
          {/* <Col xs={8} md={8} className={'m-3 text-center'}>
        <h2 className={'heading-txt'}>Login</h2>
    </Col> */}
          <Row className={"justify-content-center"}>
            <Col xs={6} md={4} className={"text-center"}>
              <Image src={Logo} width={250} height={150} />
            </Col>
          </Row>
          <Col xs={6} md={5}>
            <Form onSubmit={formik.handleSubmit} className={"m-4"}>
              {err ? <p className="text-danger">{err}</p> : ""}
              <Form.Group className="mb-2">
                <Form.Label htmlFor="password" className="formLabels">
                  Password
                </Form.Label>
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="errorMsgTxt">{formik.errors.password}</div>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label htmlFor="confirmPassword" className="formLabels">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="errorMsgTxt">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-2 text-center">
                <Button
                  type="submit"
                  className={"formButton"}
                  variant="dark"
                  size="lg"
                  disabled={isLoading == true ? true : false}
                >
                  {isLoading == true ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className={"justify-content-center seprator m-4"}>
          <Col md={6} className={"text-center"}>
            <h6 className={"txt-info"}>
              Doesn't have an account yet?
              <NavLink exact to="/register" className={"txt-link"}>
                Register
              </NavLink>{" "}
              |{" "}
              <NavLink exact to="/login" className={"txt-link"}>
                Login
              </NavLink>
            </h6>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default ResetPassword;
