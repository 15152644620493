import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

import AdminLayout from "layouts/Admin.js";
import Login from "views/Login";
import Register from "views/Register";
import ForgotPassword from "views/ForgotPassword";
import ResetPassword from "views/ResetPassword";
import Response from "views/Response/Response"
import Success from "views/Response/Success";
import VerifyEmail from "views/VerifyEmail";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route 
        path="/admin" 
        render={(props) =>
          localStorage.getItem('accessToken') ? <AdminLayout {...props} /> : <Redirect to="/login" />
        }/>
      <Route 
        path="/login" 
        render={(props) => <Login {...props} />}
         />
      <Route 
        path="/register" 
        render={(props) => <Register {...props} />} 
        />
      <Route path="/forgot_password" render={(props) => <ForgotPassword {...props} />} />
      <Route path="/reset_password" render={(props) => <ResetPassword {...props} />} />
      <Route path="/response" render={(props) => <Response {...props} />} />
      <Route path="/success" render={(props) => <Success {...props} />} />
      <Route path="/verify_email" render={(props) => <VerifyEmail {...props} />} />
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
