import React, { useContext } from "react";

// import AsyncStorage from '@react-native-community/async-storage';
import Path from "../config/Path";
// import Context from '../ContextAPI/Context';

let token = "";

export const getToken = async () => {
  // const context = useContext(Context);
  // token = context.authToken;
  // token = localStorage.setItem('')

  if (token) {
    return token;
  }
  // token = await AsyncStorage.getToken();
  return token;
};

const GeneralMiddleWare = {
  //   login: async (Path, data) => {
  //     return 'success';
  //   },

  //   delete: async (Path) => {
  //     try {
  //       const headers = {
  //         'x-auth-token': token,

  //         'Content-Type': 'application/json',
  //       };
  //       const response = await fetch(Path + '/' + endPoint, {
  //         method: 'DELETE',
  //         headers: {
  //           'x-auth-token': await AsyncStorage.getItem('authToken'),
  //           'Content-Type': 'application/json',
  //         },
  //       });

  //       const data1 = await response.json();
  //       return data1 || true;
  //     } catch (error) {
  //       console.log('error in delete request');
  //       return null;
  //     }
  //   },
  get: async (endPoint) => {
    const response = await fetch(Path + "/" + endPoint, {
      method: "GET",
      headers: {},
    })
      .then((response) => response.json())
      .then((data) => {
        return { success: true, data };
      })
      .catch((error) => {
        return { success: false, error };
      });
    return response;
  },

  //   put: async (endPoint) => {
  //     // console.log(token);
  //     try {
  //       const headers = {
  //         'x-auth-token': token,
  //       };
  //       const response = await fetch(Path + '/' + endPoint, {
  //         headers: headers,
  //       })
  //         .then((response) => response.json())
  //         .then((res) => alert('res ' + res))
  //         .catch((error) => {
  //           alert('err ' + error);
  //         });
  //     } catch (error) {
  //       console.log('error in put API ', error);
  //     }
  //   },

  // getNoAuth: async (endPoint) => {
  //     try {
  //       const auth = await getToken();

  //       const headers = {
  //         'Content-Type': 'application/json',
  //       };
  //       const response = await fetch(Path + '/' + endPoint, {
  //         method: 'GET',
  //         headers: headers,
  //       });
  //       const data = await response.json();
  //       return data;
  //     } catch (error) {
  //       return null;
  //     }
  //   },

  post: async (endPoint, data) => {
    const auth_token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(Path + "/" + endPoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          auth_token: auth_token,
        },
        body: JSON.stringify(data),
      });
      const data1 = await response.json();
      console.log("sdfa", data1);
      return data1 || true;
    } catch (error) {
      return null;
    }
  },
};

export default GeneralMiddleWare;
